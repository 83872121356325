<template>
  <div id="parkingInfo">
    <h2>停车场信息</h2>
    <el-table
        class="parkingInfo-table hideScrollBar"
        :data="tableData"
        :row-class-name="tableRowClassName">
      <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="parkingLotAddress"
          label="停车场地址"
          align="center"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          label="剩余/总车位"
          min-width="85"
          align="center"
          show-overflow-tooltip>
        <template #default="scope">
          <span class="parkingSpace">{{ scope.row.idleLots }}/{{ scope.row.totalLots }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="车载率"
          align="center"
          show-overflow-tooltip>
        <template #default="scope">
          <span :class="scope.row.class">{{ scope.row.proportion }}</span>
        </template>
      </el-table-column>
      <el-table-column width="60">
        <template #default="scope">
          <img @click="goToLocation(scope.row)" src="../../assets/images/bigScreen/parking-icon.png" alt="">
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  computed: {
    tableData() {
      this.refreshScroll()
      return this.$store.state.bigScreen.parkingLotList
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    refreshScroll() {
      this.$nextTick(() => {
        let table = document.getElementsByClassName("parkingInfo-table")[0]
        let height = table.querySelector(".el-table__body-wrapper").offsetHeight
        table.scrollTo(0, height)
      })
    },
    tableRowClassName({row, rowIndex}) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'bg';
      }
      return 'bg2';
    },
    async getTableData() {
      try {
        let res = await this.$http.post("/parking/list", {
          pageNum: 1,
          pageSize: 999,
          // businessCircleId: this.businessCircleId,
          // parkingLotId: this.parkingLotId,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.proportion = item.totalLots ? ((item.totalLots - item.idleLots) / item.totalLots * 100).toFixed(2) + "%" : "--"
            if(parseInt(item.proportion)>=95){
              item.class="redProportion"
            }
          }
          this.$store.commit("bigScreen/SET_PARKINGLOTLIST", res.data.list)
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    goToLocation(row) {
      this.$emit("parkingLotClick", row.parkingLotId)
    }
  }
}
</script>

<style lang="scss">
#parkingInfo {
  height: 40%;
  margin-bottom: 2rem;
  .el-table {
    width: 100%;
    height: calc(100% - 3.87rem);
    background-color: transparent;
    overflow-y: auto;

    &:before {
      background-color: transparent;
    }

    img {
      width: 1.94rem;
      object-fit: contain;
      cursor: pointer;
    }

    td.el-table__cell,
    th.el-table__cell.is-leaf {
      border-bottom: 0;
    }

    .cell {
      font-size: 0.88rem;
      color: white;
    }

    .parkingSpace {
      color: #fff557;;
    }

    tr {
      background-color: transparent;

      &:hover td.el-table__cell {
        background-color: initial;
      }
    }

    .bg, th.el-table__cell {
      background: rgba(101, 198, 231, 0.2);
    }

    .bg2 {
      background: rgba(101, 198, 231, 0.05);
    }

    .redProportion {
      color: red;
    }
  }
}
</style>
